import React, {useContext, createContext, useRef, useEffect } from 'react'

import { useRedux } from '../../../hooks';
// components

import config from '../../../config';


import Hotjar from '@hotjar/browser';
import ReactGA from "react-ga4";
import { useTranslation } from 'react-i18next';

import {
    updateIsLoading,
    updateIsCompleted,
    updateProgress,
    changeGenesExpressionGenesNameList,
    changeIsDeIntegration,
    clearAllGraphs,
    changeClusteringLastSent,
    clearGenesExpressionGraphs,
    clearDiffGenesExpressionGraphs,
    clearClustersGraphs,
    clearClusterInfo,
    clearClusterMarkers,
    clearDeIntegrationData,
    clearPcaData,
    clearSubClusters,
    updataClusterProjectionType,
    updataGenesExpressionRawCountType,
    clearDiffGenesExpression2,
    addNotification,
    updateWebsocketSendMessageAck
} from '../../../redux/actions';

import { SocketContext } from '../../../websocketComputation/SocketContext';
import Util from '../../../utils/GeneralUtils';


const ComputeStepContext = createContext(null)
export { ComputeStepContext }

export default ({ children }) => {

    const { dispatch, appSelector } = useRedux();

    const ws = useContext(SocketContext);
    const { t } = useTranslation();

    const {
        ws_connected,
        min_cells,
        qc_filters,
        currentSamplesExp,
        genes_number_used,
        integration_merge_enabled,
        integration_merge_metadata,
        dataSelection_metadatas,
        clustering_projection,
        clustering_projection_louvain_resolution,
        clustering_projection_pc_number,
        clustering_tsne_perplexity,
        clustering_tsne_early_exaggeration,
        clustering_projection_last_sent,
        clustering_projection_pc_number_last_sent,
        clustering_tsne_perplexity_last_sent,
        clustering_tsne_early_exaggeration_last_sent,

        diffGenesExpressionMetadatas,
        diffGenesExpressionMetadatasVs,
        diffGenesExpressionClusters,
        diffGenesExpressionClustersClusters,
        diffGenesExpressionClustersClustersVs,
        diffGenesExpressionType,
        metadatas_values,
        dataSelection_experiment,

        geneExpression_rawCounts,
        geneExpression_sortedScatter,

        geneExpression_genesNameList,
        vSubClustering,
        isDeIntegration,
        websocketSendMessageAck,
        dictUserRights,
        user_access_level,
    } = appSelector((state) => ({
        ws_connected : state.Websocket.connection_connected,
        min_cells: state.formAnalysis.filter1_minCellPerGene,
        qc_filters : state.formAnalysis.qc_filters,
        currentSamplesExp: state.Websocket.currentSamplesExp,

        genes_number_used: state.formAnalysis.genes_number_used,
        integration_merge_enabled:  state.formAnalysis.integration_merge_enabled,
        integration_merge_metadata: state.formAnalysis.integration_merge_metadata,
        dataSelection_metadatas  : state.Params.dataSelection_metadatas,

        clustering_projection:                      state.formAnalysis.clustering_projection,
        clustering_projection_louvain_resolution:   state.formAnalysis.clustering_projection_louvain_resolution,
        clustering_projection_pc_number:            state.formAnalysis.clustering_projection_pc_number,
        clustering_tsne_perplexity:                 state.formAnalysis.clustering_tsne_perplexity,
        clustering_tsne_early_exaggeration:         state.formAnalysis.clustering_tsne_early_exaggeration,

        clustering_projection_last_sent:                      state.formAnalysis.clustering_projection_last_sent,
        clustering_projection_pc_number_last_sent:            state.formAnalysis.clustering_projection_pc_number_last_sent,
        clustering_tsne_perplexity_last_sent:                 state.formAnalysis.clustering_tsne_perplexity_last_sent,
        clustering_tsne_early_exaggeration_last_sent:         state.formAnalysis.clustering_tsne_early_exaggeration_last_sent,

        diffGenesExpressionMetadatas: state.formAnalysis.diffGenesExpressionMetadatas,
        diffGenesExpressionMetadatasVs: state.formAnalysis.diffGenesExpressionMetadatasVs,
        diffGenesExpressionClusters: state.formAnalysis.diffGenesExpressionClusters,
        diffGenesExpressionClustersClusters: state.formAnalysis.diffGenesExpressionClustersClusters,
        diffGenesExpressionClustersClustersVs: state.formAnalysis.diffGenesExpressionClustersClustersVs,
        diffGenesExpressionType: state.formAnalysis.diffGenesExpressionType,
        metadatas_values         : state.Params.metadatas_values,
        dataSelection_experiment : state.formAnalysis.dataSelection_experiment,

        geneExpression_rawCounts: state.formAnalysis.geneExpression_rawCounts,
        geneExpression_sortedScatter: state.formAnalysis.geneExpression_sortedScatter,

        geneExpression_genesNameList: state.formAnalysis.geneExpression_genesNameList,
        vSubClustering:                 state.formAnalysis.vSubClustering,
        isDeIntegration: state.Params.isDeIntegration,
        websocketSendMessageAck: state.Websocket.websocketSendMessageAck,
        dictUserRights: state.formAnalysis.dictUserRights,
        user_access_level:  state.Auth.role,
    }));

    /*
    const {
        sendMessage,
        readyState
      } = useWebSocket(config.WS_URL, {
        share:true
      });
*/

    const refWebsocketSendMessageAck = useRef(websocketSendMessageAck);

     // update the ref every time your selector changes
    useEffect(() => {
        refWebsocketSendMessageAck.current = websocketSendMessageAck;
    }, [websocketSendMessageAck]);


    const vPartialSteps = ["clustering_step_6_markers","clustering_step_6_3d_projection", "clustering_step_6_subclusters"]
    const vSteps = ["updateFiles_step_1", "QC_step_3", "DE_Integration_step_2", "highlyFeatureVariables_step_4",
        "PCA_step_5", "clustering_step_6", "geneExpression_step_7", "differentialGeneExpression_step_8"]


    const isUserCanCompute = () => 
    {
        const trial_version_days = 30

        var isFreeUser = true
        var isPaidUserOverTimeOk = false
        var isTrialVersionValid = false
        var dateCurrent = new Date()
        var date_sub = new Date()

        console.log("dictUserRights user_access_level")

        if(user_access_level >= 1)
            return ""

        if(config.ENABLE_TRIAL_BYPASS)
            return ""
            
        if ("account_creation_date" in dictUserRights)
        {
            var dateAccountCreation = new Date(dictUserRights["account_creation_date"])
            var remainingDaysTrialVersion = trial_version_days - Math.floor((dateCurrent.getTime()-dateAccountCreation.getTime())/(24*3600*1000));
            isTrialVersionValid = remainingDaysTrialVersion >= 0
        }

        if ("interval" in dictUserRights)
        {
            console.log(" dictUserRights sub_begin_date:", dictUserRights["sub_begin_date"])
            date_sub = new Date(dictUserRights["sub_begin_date"])
            console.log("dictUserRights sub_begin_date date_sub:", date_sub)
            
            console.log("dictUserRights sub_begin_date dateCurrent:", dateCurrent)
        
            var days_since_sub = Math.floor((dateCurrent.getTime()-date_sub.getTime())/(24*3600*1000))+1;

            console.log(" dictUserRights days_since_sub:", days_since_sub)

            if (dictUserRights["interval"] === "month")
            {
                console.log("dictUserRights monthly ")
                isFreeUser = false
                isPaidUserOverTimeOk = (days_since_sub <= 33)
            }
            else if (dictUserRights["interval"] === "year")
            {
                console.log("dictUserRights yearly ")
                isFreeUser = false
                isPaidUserOverTimeOk = (days_since_sub <= 375)
            }

            console.log(" dictUserRights isPaidUserOverTimeOk:", isPaidUserOverTimeOk)
        }


        if (isFreeUser && !isTrialVersionValid)
        {
            return t("Your trial version has ended. To continue using the software, please subscribe")
        }
        else if (!isFreeUser && !isPaidUserOverTimeOk)
        {
            return t("Your subscription has ended. To continue using the software, please subscribe again.")
        }
        else
        {
            return ""
        }

    }

    const setCompleteStepAndAfter = (step, isCompleted) => {

        var stepIndex = vSteps.indexOf(step)
        for(var i= stepIndex ; i < vSteps.length; i++)
        {
            dispatch(updateIsCompleted(vSteps[i], isCompleted));
            dispatch(updateProgress(vSteps[i], 0));
        }
    }

    const getClusteringForm = () => {
        var json1 = {"clustering_type": clustering_projection,
        "nb_pc_for_clustering": clustering_projection_pc_number,
        "louvain_resolution": clustering_projection_louvain_resolution}

        var json2 = {}
    
        switch(clustering_projection.trim())
        {
        case "UMAP":
            //return this.state.projection_algorithm + ";" + this.state.nb_of_PC.toString() + ";" + this.state.clustering_resolution.toString()
        break;

        case "TSNE":
            json2 = {"tsne_perplexity":clustering_tsne_perplexity,
                        "tsne_early_exageration":clustering_tsne_early_exaggeration}
            
        break;

        default:
        }

        var json = Object.assign({}, json1, json2);

        console.log("getClusteringForm ", json)
        
        return json
    }

    const getSubClustering = () => {
        console.log("getSubClustering vSubClustering=", vSubClustering)
        console.log("getSubClustering json=", JSON.stringify(vSubClustering))

        var json = {"sub_clustering":JSON.stringify(vSubClustering)}

        return json
    }

    const getDiffGeneExpressionJson =() => {
        var vSelectedMetadatas = []
        var vSelectedMetadatasVs = []
        var vSelectedClusters = []

        var vSelectedClustersClusters=[]
        var vSelectedClustersClustersVs=[]

        for(let metadata of diffGenesExpressionMetadatas)
        {
            vSelectedMetadatas.push(metadata.value)
        }

        for(let metadata of diffGenesExpressionMetadatasVs)
        {
            vSelectedMetadatasVs.push(metadata.value)
        }

        for(let clusterDict of diffGenesExpressionClusters)
        {
            vSelectedClusters.push(clusterDict.value)
        }

        for(let cluster of diffGenesExpressionClustersClusters)
        {
            vSelectedClustersClusters.push(cluster.value)
        }

        for(let cluster of diffGenesExpressionClustersClustersVs)
        {
            vSelectedClustersClustersVs.push(cluster.value)
        }

        return {
                "metadatas":        vSelectedMetadatas,
                "metadatas_versus": vSelectedMetadatasVs,
                "clusters":         vSelectedClusters,
                "samples_metadatas":metadatas_values[dataSelection_experiment.label],
                "type":             isDeIntegration ? diffGenesExpressionType : "clusters",
                "clustersGroup1": vSelectedClustersClusters,
                "clustersGroup2": vSelectedClustersClustersVs,            
            }
    }

    const compute = ( step) => {

        console.log("ComputeStepContext step: ", step)
        if (config.ENABLE_ANALYTICS)
        {
            ReactGA.event({
                category: "compute",
                action: step,
                //label: "your label", // optional
            });
            Hotjar.event(step);
        }

        if (!ws_connected)
        //if (readyState !==1)
        {
            console.log("ComputeStepContext warn: socket not connecter")
            return;
        }
       

        var stepIndex = vSteps.indexOf(step)
        var stepIndexPartial = vPartialSteps.indexOf(step)
        if (stepIndex <0 && stepIndexPartial<0)
        {
            console.log("ComputeStepContext step not found:",step)
            return;
        }

        console.log("ComputeStepContext stepIndex: ", stepIndex)

        var errorSub = isUserCanCompute()
        if (errorSub.length > 0)
        {
            dispatch(addNotification(
                {description: errorSub,
                type:"error",
                delay:5000}
            ))

            return
        }

    
        var json = {}
        var message_header = ""
        switch(step)
        {
            case "updateFiles_step_1":
                dispatch(updateIsCompleted("updateFiles_step_1", false))

                let isDeIntegration = Object.keys(currentSamplesExp).length > 2
                console.log("QualityControl_step_parameters isDeIntegration=",isDeIntegration)
                dispatch(changeIsDeIntegration(isDeIntegration));
                return;

            case "QC_step_3":
                message_header = "ApplyParametersFiltering"
                console.log("currentSamplesExp ", currentSamplesExp)

                dispatch(changeGenesExpressionGenesNameList([]))
                dispatch(clearAllGraphs())
                dispatch(changeClusteringLastSent("", 0.0, 0, 0, 0))
                dispatch(clearDiffGenesExpression2())

                setCompleteStepAndAfter("QC_step_3", false)
                break;

            case "DE_Integration_step_2":
                message_header = "compute_de_integration"

                setCompleteStepAndAfter("DE_Integration_step_2", false)
                dispatch(changeClusteringLastSent("", 0.0, 0, 0, 0))

                dispatch(clearDiffGenesExpressionGraphs())
                dispatch(clearGenesExpressionGraphs());
                dispatch(clearClustersGraphs());
                dispatch(clearClusterInfo());        
                dispatch(clearDeIntegrationData());
                dispatch(clearPcaData());
                break;

            case "highlyFeatureVariables_step_4":
                message_header = "compute_highly_feature_var"

                setCompleteStepAndAfter("highlyFeatureVariables_step_4", false)

                dispatch(changeClusteringLastSent("", 0.0, 0, 0, 0))
                dispatch(clearGenesExpressionGraphs());
                dispatch(clearClustersGraphs());
                dispatch(clearClusterInfo());
                dispatch(clearDeIntegrationData());
                dispatch(clearPcaData());
                break;

            case "PCA_step_5":
                message_header = "compute_pca"

                dispatch(clearDiffGenesExpressionGraphs());
                dispatch(clearGenesExpressionGraphs());
                dispatch(clearClustersGraphs());
                dispatch(clearClusterInfo());
                //dispatch(clearDeIntegrationData());
                dispatch(clearPcaData()); 
                
                dispatch(changeClusteringLastSent("", 0.0, 0, 0, 0))
                setCompleteStepAndAfter("PCA_step_5", false)

                break;

            case "clustering_step_6_3d_projection":
                message_header = "compute_3d_umap"
                dispatch(updateProgress("clustering_step_6", 0));
                dispatch(updateIsLoading("clustering_step_6", true));
                dispatch(updateIsCompleted("clustering_step_6", false));

                break;

            case "clustering_step_6_markers":
                message_header = "compute_markers"
                dispatch(clearClusterMarkers());
                dispatch(updateProgress("clustering_step_6", 0));
                dispatch(updateIsLoading("clustering_step_6", true));
                dispatch(updateIsCompleted("clustering_step_6", false));
            break;

            case "clustering_step_6_subclusters":
                message_header = "compute_subclusters"
                dispatch(updateProgress("clustering_step_6", 0));
                dispatch(updateIsLoading("clustering_step_6", true));
                dispatch(updateIsCompleted("clustering_step_6", false));

                dispatch(clearClusterMarkers());
                dispatch(clearDiffGenesExpressionGraphs())
                dispatch(clearGenesExpressionGraphs())
                dispatch(clearDiffGenesExpression2())
                setCompleteStepAndAfter("geneExpression_step_7", false)
            break;

            case "clustering_step_6":
                let isComputeOnlyCluster = clustering_projection_last_sent === clustering_projection &&
                    clustering_projection_pc_number_last_sent === clustering_projection_pc_number && 
                    clustering_tsne_perplexity_last_sent === clustering_tsne_perplexity && 
                    clustering_tsne_early_exaggeration_last_sent === clustering_tsne_early_exaggeration;

                message_header = (isComputeOnlyCluster ? "clustering_only" : "clustering")
                console.log("ComputeStep clustering_projection",clustering_projection)

                dispatch(changeClusteringLastSent(clustering_projection, clustering_projection_louvain_resolution,
                    clustering_projection_pc_number, clustering_tsne_perplexity,clustering_tsne_early_exaggeration))

                dispatch(updataClusterProjectionType(clustering_projection))
                dispatch(clearDiffGenesExpressionGraphs())
                dispatch(clearGenesExpressionGraphs())
                dispatch(clearClustersGraphs())
                dispatch(clearClusterInfo())
                dispatch(clearDiffGenesExpression2())
                dispatch(clearSubClusters())
            
                setCompleteStepAndAfter("clustering_step_6", false)

                break;


            case "geneExpression_step_7":
                message_header = "computation_with_genes"

                console.log("onComputeGenesExpression geneExpression_rawCounts.label:",geneExpression_rawCounts.label)
                dispatch(updataGenesExpressionRawCountType(geneExpression_rawCounts.label))
                dispatch(clearGenesExpressionGraphs());

                var vGenesName = []
                for (var select_val of geneExpression_genesNameList)
                {
                    vGenesName.push(select_val.value)
                }

                var jsonGeneExpression = {"genes": vGenesName,
                    "count_type":geneExpression_rawCounts.value.toString(), 
                    "scatter_sort_type":geneExpression_sortedScatter.value.toString(), 
                    }

                json = Object.assign({}, json, jsonGeneExpression);
                break;

            case "differentialGeneExpression_step_8":
                dispatch(clearDiffGenesExpressionGraphs());
                message_header = "compute_differential_gene_expression"

                var jsonDiffGeneExpression =  getDiffGeneExpressionJson()
                json = Object.assign({}, json, jsonDiffGeneExpression);
                break;

            default:
                console.log("error case not found")
                break
                
        }

        
        if (stepIndex>=0)
        {
            dispatch(updateProgress(step, 0));
            dispatch(updateIsLoading(step, true));
            dispatch(updateIsCompleted(step, false));
        }
        

        if (stepIndex >= vSteps.indexOf("QC_step_3") || stepIndexPartial>=0)
        {     
                 
            var json_samples = {["sample_exp_names"]: currentSamplesExp}
            /*  
            var json_1 = {"Nfeatures_min":features_min, "Nfeatures_max":features_max, "percent_mito_max":mito_percent_max}
            var json_2 = {"cells_min":min_cells, "features_min":min_features}
            */
            let json_1 = {"cells_min":min_cells}
            json = Object.assign({}, json, json_1, json_samples);
        }

        if (stepIndex > vSteps.indexOf("QC_step_3") || stepIndexPartial>=0)
        {
            /*
            // qc_filters: [sample_name][umi/NFeatures/mito][min/max]
            var qc_filter_as_list = []
            for (const [sampleName, sampleValues] of Object.entries(qc_filters))
            {
                for (const [type, dictMinMax] of Object.entries(sampleValues))
                {
                    for (const [minmax, value] of Object.entries(dictMinMax))
                    {
                        qc_filter_as_list.push({"sampleName": sampleName, "type":type, minmax: value})
                    }
                }
            }

            console.log("qc_filter_as_list :", qc_filter_as_list);

            var json_1 = {"qc_filter_one_violin": qc_filter_as_list}
            */
            let json_1 = {"qc_filter_all_violin": qc_filters}
            json = Object.assign({}, json, json_1);
        }

        //if (stepIndex <= vSteps.indexOf("highlyFeatureVariables_step_4"))
        if (stepIndex >= vSteps.indexOf("DE_Integration_step_2") || stepIndexPartial>=0)
        {
            var nb_metadata = 0
            if (dataSelection_experiment.label in dataSelection_metadatas)
                nb_metadata = Object.keys(dataSelection_metadatas[dataSelection_experiment.label]).length

            json = Object.assign({}, json, {
                "genes_number_used": genes_number_used,
                "samples_metadatas":metadatas_values[dataSelection_experiment.label],
                "integration_merge_enabled":  nb_metadata > 0 ? integration_merge_enabled : false,
                "integration_merge_metadata": "label" in integration_merge_metadata ? integration_merge_metadata.label : ""});
        }

        if (stepIndex >= vSteps.indexOf("clustering_step_6") || stepIndexPartial>=0)
        {
            json = Object.assign({}, json, getClusteringForm());
            json = Object.assign({}, json, getSubClustering());
            
            var metaValues = dataSelection_experiment.label in metadatas_values ? metadatas_values[dataSelection_experiment.label] : {}
            var metadatas = dataSelection_experiment.label in dataSelection_metadatas ? dataSelection_metadatas[dataSelection_experiment.label] : {}
            json = Object.assign({}, json, {"samples_metadatas": metaValues});
            json = Object.assign({}, json, {"all_metadatas": metadatas});
            
           /*
            json = Object.assign({}, json, {"samples_metadatas": metadatas_values[dataSelection_experiment.label]});
            json = Object.assign({}, json, {"all_metadatas": dataSelection_metadatas[dataSelection_experiment.label]});
                */

        }        
        
        console.log("json: metadatas_values[dataSelection_experiment.label]",metadatas_values[dataSelection_experiment.label])
        console.log("json: dataSelection_metadatas[dataSelection_experiment.label]",dataSelection_metadatas[dataSelection_experiment.label])
        
        console.log("json: ",json)

        let json_merge_str = JSON.stringify(json)
        let message = message_header + ";" + json_merge_str
        console.log("ComputeStepContext message=", message)

        
        if (ws !==null)
        {
            console.log(Util.getTimeString() + " ComputeStepContext WebsocketInst websocket:", websocketSendMessageAck)
            dispatch(updateWebsocketSendMessageAck(1))

            if (ws!== undefined)
                ws.send(message)

            setTimeout(function () {

                console.log(Util.getTimeString() + " ComputeStepContext WebsocketInst websocketSendMessageAck:", websocketSendMessageAck)

              if (refWebsocketSendMessageAck.current === 1)
              {
                console.warn("ComputeStepContext warning ack not received")
                dispatch(addNotification(
                    {description:t("Not connected to server, please retry."),
                    type:"warning",
                    delay:3000}
                ))

                dispatch(updateIsLoading("updateFiles_step_1", false));
                dispatch(updateIsLoading("QC_step_3", false));
                dispatch(updateIsLoading("DE_Integration_step_2", false));
                dispatch(updateIsLoading("highlyFeatureVariables_step_4", false));
                dispatch(updateIsLoading("PCA_step_5", false));
                dispatch(updateIsLoading("clustering_step_6", false));
                dispatch(updateIsLoading("geneExpression_step_7", false));
                dispatch(updateIsLoading("differentialGeneExpression_step_8", false));
              }
              else
              {
                console.log("ComputeStepContext ack received")
              }

            }, 10000);
        }
        

    }

    let computeStep = {
        compute
    }

    return (
        <ComputeStepContext.Provider value={computeStep}>
            {children}
        </ComputeStepContext.Provider>
    )

}

