// WebSocket.js
import { useDispatch, useSelector } from 'react-redux';
import config from '../config';

import { useRedux } from '../hooks';

//import useWebSocket from 'react-use-websocket';

import Util from '../utils/GeneralUtils';


//import { ComputeStepContext } from  '../pages/apps/common/ComputeStep';
import {
   // changeLastMessage,
    changeConnectionStatus,
    askUserData,
    updataData,
    changeAllMetadatas,
    changeAllMetadatasValues,
    changeQcFilter,
    updataQcViolinRawCounts,
    updataQcViolinGenesNbPerCell,
    updataQcViolinPercentMt,
    updataQcCellsNumberAfterFilters,
    changeDefaultQcFilter,
    updataQcGenesNumberAfterFilters,
    updataQcScatterGeneNbCounts,
    updataQcScattePercentMtCounts,
    updataSamplesName,
    updataQcComplexityComplexity,
    updataQcComplexityCounts,
    updataQcComplexityFeatures,
    updataQcComplexityPercentMt,
    updataHighlyFeatureGenes,
    updataFeatureGenesTable,
    updateProgress,
    updateProgressBackend,
    updateIsCompleted,
    updateIsLoading,
    cancelCurrentTask,
    updataPcaElbow,
    updataHeatmaps,
    updataClusterScatter2d,
    updataClustersCellsNbTable,
    updataClustersCellsNbCsv,
    updataClusterMarkers,
    changeClusterSampleFilter,
    updataClusterMarkersCSv,
    updataClusterScatter3d,
    updataGenesExpressionGenesName,
    updataGenesExpressionViolinGenes,
    updataGenesExpressionScatterGenes,
    updataGenesExpressionTable,
    updataGenesExpressionTableCsv,
    updataGenesExpressionTableFromCluster,
    changeClusterList,
    changeRole,

    updateH5MatricesFiles ,
    updateH5BarcodesFile,
    updateH5FeaturesFile,

    //Params
    changeClusterNames,

    addNotification,
    setBackendStats,
    setBackendAllUsers,
    updateH5Treeview,
    updateAllWebsocketData,
    updateAllParamsData,
    updateAllFormAnalysisData,
    updateAllProgressData,
    updateUserSavesList,

    setUserSaveLoadLoading,
    updateServerInfos,
    updateWebsocketAck,
    updateWebsocketForceReload,
    updateWebsocketSendMessageAck,
    updateUserDataMemoryUsage,
    updateUserSavedAnalysisMemoryUsage,
    updateUserRights,
    updateWebServerAdminInfos
    
} from '../redux/actions';



// In functional React component

import React, {
    createContext,
    useContext,
    useRef,
    useEffect,
    useState,
  } from 'react'
  
  import io from 'socket.io-client'
  
// This can also be an async getter function. See notes below on Async Urls.
const SOCKET_URL = "ws://mattox.freeboxos.fr:25881"
  export const SocketContext = createContext()
  
export const SocketProvider = ({ children, store }) =>
{
  console.log("socketio SocketProvider")
    //const [isConnected, setConnected] = useState(false)
  
    const dispatch = useDispatch();

    const user = useSelector(state => state.Auth.user)
    const clusters_clusters_user_rename = useSelector(state => state.Params.clusters_clusters_user_rename)


    const loadingProgress = useSelector(state => state.Progress.progress)
    const websocketAck = useSelector(state => state.Websocket.websocketAck)
    const webscoketForceReload = useSelector(state => state.Websocket.webscoketForceReload)
    const websocketSendMessageAck = useSelector(state => state.Websocket.websocketSendMessageAck)

   // const socket = useRef(null)
 
   const refWebsocketAck = useRef(websocketAck);
   const refWebsocketSendMessageAck = useRef(websocketSendMessageAck);
    
    const websocket_computation = useRef(null);
    var refUser = useRef(user);

    useEffect(() => {
        refUser.current = user;
    }, [user]);

    let ws;
    const connectInterval= useRef(null);;// = setInterval(webSocketHeartbeat, 20000); //call check function after timeout

    useEffect(() => {
      refWebsocketAck.current = websocketAck;
  }, [websocketAck]);
  
  useEffect(() => {
    refWebsocketSendMessageAck.current = websocketSendMessageAck;
}, [websocketSendMessageAck]);


  /*
  useEffect(() => {
    console.log("useEffect connectWebSocket",websocket_computation, user)

    // if (websocket_computation.current && user !==null)
    if ( user !==null)
    {
        connectWebSocket()
    }
   
}, [websocket_computation.current, user]);
*/

    const sendMessage = ( message) => {
      console.log("ws::sendMessage msg: ", message)
      websocket_computation.current.send(message)
    }

    const closeSocket = () => {
        console.log("WebsocketInst close socket")
        websocket_computation.current.close()
    }
      
    const webSocketHeartbeat =() => // if no data sent during more than 60sec wss websocket going to close
    {
        console.log("webSocketHeartbeat ", refUser.current, websocket_computation.current.state)
        if (websocket_computation.current !== null )
        {
            try
            {
                console.log("WebsocketInst send heartbeat")
                websocket_computation.current.send("heartbeat")
                console.log("WebsocketInst refWebsocketAck:", refWebsocketAck.current,"websocketAck:",websocketAck )
                dispatch(updateWebsocketAck(1))
                //console.log("WebsocketInst refWebsocketAck:", refWebsocketAck.current,"websocketAck:",websocketAck )

                setTimeout(function () {
                    //console.log("WebsocketInst refWebsocketAck:", refWebsocketAck.current,"websocketAck:",websocketAck )
    
                    if (refWebsocketAck.current === 1)
                    {
                        console.warn("WebsocketInst warning ack not received")
                        dispatch(changeConnectionStatus(false));
                    }
                    else
                    {
                        console.log("WebsocketInst ack received")
                    }
    
                }, 5000);
            }
            catch(error)
            {
                console.warn("webSocketHeartbeat ", refUser.current, websocket_computation.current.state)
                console.warn("webSocketHeartbeat catch, ",error)
            }
        }
        
    }

    const connectWebSocket = () =>
      {
          console.log("connectWebSocket websocket_computation === null :",websocket_computation.current === null)
      
          let isSocketConnected = websocket_computation.current !== null &&  websocket_computation.current.readyState === WebSocket.connected
          console.log('connectWebSocket isSocketConnected:',isSocketConnected)
  
          console.log('connectWebSocket websocket_computation:',websocket_computation.current, "websocket_computation.readyState:", websocket_computation.current !== null && websocket_computation.current.readyState )
  
          
         if (!isSocketConnected)
         {
              console.log("new WebSocket !! connectWebSocket")
              console.log('new WebSocket !! websocket_computation:',websocket_computation.current, "websocket_computation.readyState:", websocket_computation.current !== null && websocket_computation.current.readyState )
              console.log('new WebSocket !! isSocketConnected:',isSocketConnected )
  
  
              websocket_computation.current = new WebSocket(config.WS_URL);
              clearTimeout(connectInterval.current);
              connectInterval.current = setInterval(webSocketHeartbeat, 20000); //call check function after timeout
         }
         else
         {
          return ;
         }
         
          
  
          /*
          setTimeout(function() {
              this.connectWebSocket();
          }
          .bind(this)
          , 1000);
          */
  
           websocket_computation.current.onmessage = evt => { 
          
            dispatch(changeConnectionStatus(true));
          console.log("websocket::onmessage ", evt.data)

          if (evt.data ===  "heartbeat_response")
          {
            dispatch(updateWebsocketAck(0))
            //console.log("websocket heartbeat_response !!!", "refWebsocketAck:", refWebsocketAck.current,"websocketAck:",websocketAck )
            return
          }

          var dataFromSocket = evt.data.split('|')
   
          if (dataFromSocket.length ===2)
          {
              let args = dataFromSocket[0].split(';')

              console.log("websocket mess received")

              if( refWebsocketSendMessageAck.current === 1)
              {
                    console.log(Util.getTimeString() + " websocket::onmessage refWebsocketSendMessageAck will be pass to 0 ")
              }

              dispatch(updateWebsocketSendMessageAck(0))

               if (args[0] === 'user_data_tree')
              {
                  let access_level = args[1]
                  console.log("websocket::onmessage user_data_tree!! access_level:",access_level);
                  dispatch(askUserData(true));
                  dispatch(changeRole(access_level))
  
                  let data = JSON.parse(dataFromSocket[1])
                  
                  console.log("websocket::onmessage data:", data)
                  dispatch(updataData(data))
  
                  return;
              }
              else if (args[0] === 'user_metadatas_struct')
              {
                  console.log("websocket::onmessage user_metadatas_struct!!: ", dataFromSocket[1], dataFromSocket[1].length);
  
                  if (dataFromSocket[1].length >2)
                  {
                      let data = JSON.parse(dataFromSocket[1])
                  
                      console.log("websocket::onmessage data:", data)
                      dispatch(changeAllMetadatas(data))
                  }
  
                  return;
              }
              else if (args[0] === 'user_metadatas_values')
              {
                  console.log("websocket::onmessage user_metadatas_values!! ", dataFromSocket[1], dataFromSocket[1].length);
  
                  if (dataFromSocket[1].length >2 )
                  {
                      let data = JSON.parse(dataFromSocket[1])
                      
                      console.log("websocket::onmessage data:", data)
                      dispatch(changeAllMetadatasValues(data))
                  }
  
                  return;
              }
              else if (dataFromSocket[0] === "progress")
              {
                  
                  let args = dataFromSocket[1].split(';')
  
                  if (args.length === 2)
                  {
                      var stepName = args[0]               
                      var progress  = parseInt(args[1])
                      console.log("onNewWebSocketMessage stepName ", stepName," progress:",progress )
  
                      if (stepName === "saveLoadBackend")
                      {
                          console.log("onNewWebSocketMessage saveLoadBackend !!! ", progress)
                          if (progress === 100)
                          {
                              dispatch(setUserSaveLoadLoading(false))
                          }
  
                          dispatch(updateProgressBackend(progress))
                      }
                      else
                      {
                          if (progress ===100)
                          {
                              console.log("onNewWebSocketMessage ",stepName," updateIsCompleted")
                              dispatch(updateIsCompleted(stepName, true))
                              dispatch(updateIsLoading(stepName, false))
  
                              if (stepName ==="highlyFeatureVariables_step_4" || stepName ==="DE_Integration_step_2")
                              {
                                  console.log("onNewWebSocketMessage ask PCA_step_5 !!")
                                  
                                  dispatch(updateIsLoading("PCA_step_5", true))
                              }
                          }
      
                          if (stepName in loadingProgress && loadingProgress[stepName] !== progress)
                              dispatch(updateProgress(stepName, progress))
                      }
  
  
                  }                
  
              }
              else if (dataFromSocket[0] === "error")
              {
                  console.log("onNewWebSocketMessage::error !")
  
                  var errorList = dataFromSocket[1].split(';')
  
                  if (errorList.length === 2)
                  {
                      console.log("onNewWebSocketMessage::error :", errorList[0], " ", errorList[1]) 
  
                      var iMsgType = parseInt(errorList[0])
                      var variant_type = "error"
                      var msg_duration = 10000
  
                      switch(iMsgType)
                      {
                          case 1:
                              
                              variant_type = "error"
                              msg_duration = 10000
                          break;
  
                          case 2:
                              variant_type = "warning"
                              msg_duration = 10000
                          break;
  
                          case 3:
                              variant_type = "info"
                              msg_duration = 5000
                          break;
  
                          case 4:
                              variant_type = "success"
                              msg_duration = 5000
                          break;
  
                          default:
                              console.log("Warning unexpected case")
                              variant_type = "error"
                              msg_duration = 10000
                          break;
                      }
  
                      if (variant_type ==="error")
                      {
                          dispatch(cancelCurrentTask())
  
                          dispatch(setUserSaveLoadLoading(false))
                      }    
                  
                      
                      dispatch(addNotification(
                          {description:errorList[1],
                          type:variant_type,
                          delay:msg_duration}
                      ))
  
                  }
              }
              else if (args[0] === 'GenesNb')
              {
                  console.log("websocket::onmessage GenesNb !");
                  console.log("websocket::onmessage GenesNb data:", dataFromSocket[1]);
                  var data = JSON.parse(dataFromSocket[1])
                  console.log("websocket::onmessage GenesNb data=",data);
                  dispatch(updataQcGenesNumberAfterFilters(data))
              }
              else if (args[0] === 'violin_QC')
              {
                  let sample_name = args[2]
                  console.log("websocket::onmessage violin_QC  sample_name=", sample_name);
  
                  if (args[1] === 'Raw counts sum per cell')
                  {
                      console.log("violin_QC Raw counts sum per cell !!");
                      let data = JSON.parse(dataFromSocket[1])
                      dispatch(updataQcViolinRawCounts(sample_name, data));    
                      return;
                  }
                  else if (args[1] === 'Genes number per cell')
                  {
                      console.log("violin_QC Genes number per cell !!");
                      let data = JSON.parse(dataFromSocket[1])
                      dispatch(updataQcViolinGenesNbPerCell(sample_name, data));    
                      return;
                  }
                  else if (args[1] === 'Mitochondrial percentage per cell')
                  {
                      console.log("violin_QC Mitochondrial percentage per cell !!");
                      let data = JSON.parse(dataFromSocket[1])
                      dispatch(updataQcViolinPercentMt(sample_name, data)); 
                      return;
                  }
                  
              }
              else if (args[0] === 'violin_QC_min_max')
              {
                  console.log("websocket::onmessage violin_QC_min_max AAA data=",  dataFromSocket[1]);
                  
                  let data = JSON.parse(dataFromSocket[1])
                  console.log("websocket::onmessage violin_QC_min_max !! data=", data);
  
                  dispatch(changeQcFilter(data))
                  // const clone = structuredClone(data);
                  const clone = JSON.parse(JSON.stringify(data));
                  dispatch(changeDefaultQcFilter(clone))
              }
              else if (args[0] === 'violin_QC_cells_number')
              {
                  console.log("websocket::onmessage violin_QC_cells_number data=",  dataFromSocket[1]);
                  
                  let data = JSON.parse(dataFromSocket[1])
  
                  dispatch(updataQcCellsNumberAfterFilters(data))
              }
              else if (args[0] === 'complexity')
              {
                  let sample_name = args[2]
                  console.log("websocket::onmessage complexity!! ",sample_name);
  
                  if (args[1] === 'complexity')
                  {
                      try{
                          console.log("Rcomplexity::complexity !!");
                          let data = JSON.parse(dataFromSocket[1])
                          dispatch(updataQcComplexityComplexity(sample_name, data));
                      } catch(e)
                      {
                          console.log("complexity catch error ", e);
                      }
                      return;
                  }
                  else if (args[1] === 'counts')
                  {
                      try{
                          console.log("Rcomplexity::counts !!");
                          let data = JSON.parse(dataFromSocket[1])
                          dispatch(updataQcComplexityCounts(sample_name, data));
                      } catch(e)
                      {
                          console.log("counts catch error ", e);
                      }
                      return;
                  }
                  else if (args[1] === 'features')
                  {
                      try{
                          console.log("Rcomplexity::features !!");
                          let data = JSON.parse(dataFromSocket[1])
                          dispatch(updataQcComplexityFeatures(sample_name, data));
                      } catch(e)
                      {
                          console.log("features catch error ", e);
                      }
                      return;
                  }
                  else if (args[1] === 'percentmt')
                  {
                      try{
                          console.log("Rcomplexity::percentmt !!");
                          let data = JSON.parse(dataFromSocket[1])
                          dispatch(updataQcComplexityPercentMt(sample_name, data));
                      } catch(e)
                      {
                          console.log("percentmt catch error ", e);
                      }
                      return;
                  }
  
              }
              else  if (args[0] === 'features_selection_variation')
              {
                  console.log("websocket::onmessage features_selection_variation !");
                  let data = JSON.parse(dataFromSocket[1])
                  dispatch(updataHighlyFeatureGenes(data))
                  return;
              }
              else  if (args[0] === 'featuresGenesTable')
              {
                  console.log("websocket::onmessage featuresGenesTable !");
                  let data = JSON.parse(dataFromSocket[1])
                  dispatch(updataFeatureGenesTable(data))
                  return;
              }
              else if (args[0] === 'scatter_plot')
              {
                  let sample_name = args[2]
                  console.log("websocket::onmessage scatter_plot!! sample_name: ", sample_name);
                  dispatch(updataSamplesName(sample_name))
                  //dispatch(updataTest(sample_name, args[0]))
  
                  console.log("websocket::onmessage scatter_plot args[1]: ", args[1]);
                  
                  if (args[1] === 'Genes number - counts')
                  {
                      console.log("Genes number - counts !!");
                      let dataJson = JSON.parse(dataFromSocket[1])
  
                      let scatter = {
                          data : dataJson,
                          xAxisName:"nUMI",
                          yAxisName:'nGene',
                          graphLegend: "sample "+sample_name
                      }
                      dispatch(updataQcScatterGeneNbCounts(sample_name, scatter));    
                      return;
                  }
                  else if (args[1] === 'Mitochondrial percentage - counts')
                  {
                      console.log("Mitochondrial percentage - counts !!");
                      let dataJson = JSON.parse(dataFromSocket[1])
  
                      let scatter = {
                          data : dataJson,
                          xAxisName:"nUMI",
                          yAxisName:'% mtDNA',
                          graphLegend: "sample "+sample_name
                      }
                      dispatch(updataQcScattePercentMtCounts(sample_name, scatter));    
                      return;
                  }
              }
              else if (args[0] === "genes_names")
              {
                  console.log("onNewWebSocketMessage genes_names")
  
                  let dataJson = JSON.parse(dataFromSocket[1])
                  
                  let data = dataJson.map(x => {
                      return({value: x, label:x});
                  })
                  dispatch(updataGenesExpressionGenesName(data))
  
                  /*
                  this.setState({
                      genesNames: dataJson.map(x => {
                          return({title: x});
                      })
                  })
                  */
              }
              else if (args[0] === 'elbow')
              {
                  console.log("elbow !!");
                  let dataJson = JSON.parse(dataFromSocket[1])
  
                  dispatch(updataPcaElbow(dataJson))
              }
              else if (args[0] === 'heatmap')
              {
                  console.log("heatmap !!")
                  let dataJson = JSON.parse(dataFromSocket[1])
  
                  let PCHeatmapPathsTmp = []
                  for (var i = 0; i < dataJson.length; i++)
                  {
                      console.log("data ",i, " : ",dataJson[i]);
                      dataJson[i] = dataJson[i].replace('-', '/')
                      PCHeatmapPathsTmp.push(dataJson[i])
                  }
  
                  dispatch(updataHeatmaps(PCHeatmapPathsTmp))
              }
              else if (args[0] === 'cluster_2d_plotly')
              {
                  console.log("cluster_2d !!")
                  let dataJson = JSON.parse(dataFromSocket[1])
  
                  /*
                  let cluster_custom_names = []
                  for(let i =0 ; i < dataJson.length ; i++)
                  {
                      cluster_custom_names.push("Cluster "+(i+1))
                  }
  
                  dispatch(changeClusterNames(cluster_custom_names))
                  */
                  dispatch(updataClusterScatter2d(dataJson))
                  
              }
              else if (args[0] === 'clusters_cellsnb_table')
              {
                  console.log("clusters_cellsnb_table !!")
                  let dataJson = JSON.parse(dataFromSocket[1])
    
                  dispatch(updataClustersCellsNbTable(dataJson))
                    
              }
              else if (args[0] === 'clusters_cellsnb_csv')
              {
                  console.log("clusters_cellsnb_csv !!")
                  let dataJson = JSON.parse(dataFromSocket[1])

                  dispatch(updataClustersCellsNbCsv(dataJson))  
              }
              else if (args[0] === 'list_clusters_id')
              {
                  console.log("list_clusters_id !!")
                  let dataJson = JSON.parse(dataFromSocket[1])
  
                  let cluster_custom_names = []
                  for(let i =0 ; i < dataJson.length ; i++)
                  {
                      if ( i in clusters_clusters_user_rename)
                      {
                          cluster_custom_names.push(clusters_clusters_user_rename[i])
                      }
                      else
                      {
                          cluster_custom_names.push(dataJson[i].name)
                      }
                      
                  }
  
                  dispatch(changeClusterNames(cluster_custom_names))
  
                  dispatch(changeClusterList(dataJson))
                  
              }
              else if (args[0] === 'sample_cluster_projection_filter')
              {
                  console.log("sample_cluster_projection_filter !!")
  
                  try
                  {
                      let dataJson = JSON.parse(dataFromSocket[1])
                      dispatch(changeClusterSampleFilter(dataJson))
                  }
                  catch(e)
                  {
                      console.log("sample_cluster_projection_filter parsing error", e)
                  }
                  
              }
              else if (args[0] === 'clustersMarkers')
              {
                  let clusterName = args[1]
                  console.log("clustersMarkers !! clusterName:", clusterName)
                  let dataJson = JSON.parse(dataFromSocket[1])
  
                  dispatch(updataClusterMarkersCSv(dataJson))
                  dispatch(updataClusterMarkers(clusterName, dataJson))
                  
                  //dispatch(updataClusterScatter2d(dataJson))
              }
              else if (args[0] === 'clustersDifferentialGeneExpression')
              {
                  let clusterName = args[1]
                  
                  console.log("clustersDifferentialGeneExpression !! clusterName:", clusterName, " len=",dataFromSocket[1].length)
                  let dataJson = JSON.parse(dataFromSocket[1])
  
                  console.log("clustersDifferentialGeneExpression ", dataJson)
                  console.log("clustersDifferentialGeneExpression !! dataJson:")
  
                  dispatch(updataGenesExpressionTableCsv(dataJson))
                  dispatch(updataGenesExpressionTable(clusterName, dataJson))
              }   
              else if (args[0] === 'FromClusterDifferentialGeneExpression')
              {
                  console.log("FromClusterDifferentialGeneExpression !!")
                  let dataJson = JSON.parse(dataFromSocket[1])
  
                  dispatch(updataGenesExpressionTableFromCluster(dataJson))
                  //dispatch(updataGenesExpressionTable(clusterName, dataJson))
              }   
              
              else if (args[0] === "cluster_umap_3d")
              {
                  console.log("onNewWebSocketMessage cluster_umap_3d")
      
                  let dataJson = JSON.parse(dataFromSocket[1])
  
                  dispatch(updataClusterScatter3d(dataJson))
              }
              else if (args[0] === "violin_genes")
              {
                  let geneName = args[1]
                  console.log("onNewWebSocketMessage violin_genes geneName:",geneName)
      
                  let dataJson = JSON.parse(dataFromSocket[1])
          
                  dispatch(updataGenesExpressionViolinGenes(geneName, dataJson))
              }
              else if (args[0] === "umpa_scatter_genes")
              {
                  let geneName = args[1]
                  console.log("onNewWebSocketMessage umap_scatter_genes geneName:",geneName)
      
                  let dataJson = JSON.parse(dataFromSocket[1])
  
                  dispatch(updataGenesExpressionScatterGenes(geneName, dataJson))
              }
              else if (args[0] === "backend_stats")
              {
                  let dataJson = JSON.parse(dataFromSocket[1])
  
                  console.log("onNewWebSocketMessage backend_stats:", dataJson)
                  //console.log("onNewWebSocketMessage backend_stats:", dataFromSocket[1])
  
                  dispatch(setBackendStats(dataJson))
              }
              else if (args[0] === "backend_all_users")
              {
                  let dataJson = JSON.parse(dataFromSocket[1])
  
                  //console.log("onNewWebSocketMessage   a backend_all_users:", dataJson)
                  console.log("onNewWebSocketMessage backend_all_users:", dataJson)
                  //console.log("onNewWebSocketMessage backend_all_users:", dataFromSocket[1])
  
                  dispatch(setBackendAllUsers(dataJson))
              }
              else if (args[0] === "h5treeview")
              {
                  try
                  {
  
                      console.log("onNewWebSocketMessage h5treeview !")
                      let dataJson = JSON.parse(dataFromSocket[1])
      
                      console.log("onNewWebSocketMessage h5treeview:", dataJson)
                      console.log("onNewWebSocketMessage h5treeview:", dataFromSocket[1])
      
                      dispatch(updateH5Treeview(dataJson))
                  }
                  catch(e)
                  {
                      console.log("h5treeview parsing error")
                  }
              }
              else if (args[0] === "h5auto_detected_files")
              {
                  console.log("onNewWebSocketMessage h5auto_detected_files :", dataFromSocket[1])
                  let vFiles =  dataFromSocket[1].split(",")
                  if (vFiles.length === 5) // ["matrix/data", "matrix/indices", "matrix/indptr", "matrix/barcodes", "matrix/features/name"]
                  {
                      console.log("onNewWebSocketMessage h5auto_detected_files files auto detected !")
                      dispatch(updateH5MatricesFiles([vFiles[0],vFiles[1],vFiles[2]]))
                      dispatch(updateH5BarcodesFile(vFiles[3]))
                      dispatch(updateH5FeaturesFile(vFiles[4]))
                  }
                  else
                  {
                      console.log("onNewWebSocketMessage h5auto_detected_files no files found")
                  }
                  
              }
              else if (args[0] === "load_data")
              {
                  console.log("onNewWebSocketMessage load_data :")
  
  
                  let dataJson = JSON.parse(dataFromSocket[1])
                  
                  if ("params_store" in dataJson && 
                      "websocket_store" in dataJson && 
                      "formAnalysis_store" in dataJson && 
                      "progress_store" in dataJson )
                  {
                      dispatch(updateAllParamsData(dataJson["params_store"]))
                      dispatch(updateAllFormAnalysisData(dataJson["formAnalysis_store"]))
                      dispatch(updateAllProgressData(dataJson["progress_store"]))
                      dispatch(updateAllWebsocketData(dataJson["websocket_store"]))

                      console.log("onNewWebSocketMessage load_data : webscoketForceReload", webscoketForceReload)
  
                      dispatch(addNotification(
                          {description:"Data successfuly loaded",
                          type:"success",
                          delay:5000}
                      ))
                  }
                  else
                  {
                      dispatch(addNotification(
                          {description:"Data error loading",
                          type:"error",
                          delay:5000}
                      ))
                  }
                  
              }
              else if (args[0] === "user_saves_analysis")
              {
                  console.log("onNewWebSocketMessage user_saves_analysis :", dataFromSocket[1])
  
                  let dataJson = JSON.parse(dataFromSocket[1])
  
                  dispatch(updateUserSavesList(dataJson))
              }
              else if (args[0] === "info_server")
              {
                  let dataJson = JSON.parse(dataFromSocket[1])
                  console.log("onNewWebSocketMessage server_infos dataJson :", dataJson)
  
                  dispatch(updateServerInfos(dataJson))
              }
              else if (args[0] === "user_memory_usage")
              {
                  try
                  {
                    let dataJson = JSON.parse(dataFromSocket[1])
                    console.log("onNewWebSocketMessage user_memory_usage dataJson :", dataJson)
                    dispatch(updateUserDataMemoryUsage(dataJson))
                  }
                  catch(e)
                  {
                    console.warn("onNewWebSocketMessage user_memory_usage error json parsing str dataJson :", dataFromSocket[1])
                  }
                  
              }
              else if (args[0] === "user_memory_usage_saved_analysis")
              {
                try
                {
                    let dataJson = JSON.parse(dataFromSocket[1])
                    console.log("onNewWebSocketMessage user_memory_usage_saved_analysis dataJson :", dataJson)
                    dispatch(updateUserSavedAnalysisMemoryUsage(dataJson))
                }
                catch(e)
                {
                    console.warn("onNewWebSocketMessage user_memory_usage_saved_analysis error json parsing str dataJson :", dataFromSocket[1])
                }
              }
              else if (args[0] === "user_rights")
              {
                  let dataJson = JSON.parse(dataFromSocket[1])
                  console.log("onNewWebSocketMessage user_rights dataJson :", dataJson)
                  dispatch(updateUserRights(dataJson))
              }
              else if (args[0] === "webserver_admin_infos")
              {
                  let dataJson = JSON.parse(dataFromSocket[1])
                  console.log("onNewWebSocketMessage webserver_admin_infos dataJson :", dataJson)
                  dispatch(updateWebServerAdminInfos(dataJson))
              }
  
  
              
          }
   
          //dispatch(changeLastMessage(evt.data));
        
          };
  
          
  
          websocket_computation.current.onopen = evt => {
              console.log("websocket::onopen !!! user=",user, "readyState",websocket_computation.current.readyState )
              dispatch(changeConnectionStatus(true));
  
              if (user !==null)
              {
                  console.log("websocket::onopen askUserDataWeb")
                  websocket_computation.current.send("user_login;" + user.token_server);
              }
              else
              {
                console.warn("websocket::onopen user null")
              }
          };
  
          websocket_computation.current.onclose = evt => {
              console.warn("websocket::onclose user:", refUser.current)
              dispatch(changeConnectionStatus(false));

              if( refUser.current !== null)
              {
                console.warn("websocket::onclose try connectWebSocket in 1 sec")
                setTimeout(function() {
                    connectWebSocket();
                }
                .bind(this)
                , 1000);

              }
             
          };
  
          websocket_computation.current.onerror = evt => {
              console.warn("websocket::onerror ", evt)
          };
  
          ws = {
              socket: websocket_computation.current,
              sendMessage,
              closeSocket
          }
      }
  
      /*
    useEffect(() => {

      connectWebSocket()
    }, [])
    */

    
    console.log("websocket connectWebSocket? ", websocket_computation != null, user!= null, websocket_computation.current, user)

    if (!websocket_computation.current && user !==null)
    {
        console.log("websocket connectWebSocket call connectWebSocket")
        connectWebSocket()
    }
        
  
    return (
      <SocketContext.Provider value={ websocket_computation.current}>
        {children}
      </SocketContext.Provider>
    )
  }
  
  export const useSocket = () => useContext(SocketContext)