import React, {useEffect, useContext} from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

// layout constants
import { LayoutTypes } from '../constants/layout';

// custom hook
import { useRedux } from '../hooks/';

// All layouts containers
import DefaultLayout from '../layouts/Default';
import VerticalLayout from '../layouts/Vertical';
import DetachedLayout from '../layouts/Detached';
import HorizontalLayout from '../layouts/Horizontal/';
import TwoColumnLayout from '../layouts/TwoColumn/';

//import useWebSocket from 'react-use-websocket';
import config from '../config';
// routes
import { authProtectedFlattenRoutes, publicProtectedFlattenRoutes } from './index';
import { SocketContext } from '../websocketComputation/SocketContext';

interface RoutesProps {}

const Routes = (props: RoutesProps) => {
    const { appSelector } = useRedux();
    const { layout } = appSelector((state) => ({
        layout: state.Layout,
    }));
    const {
        ws_ask_user_data,
        ws_connected,
        user,
        forceReloadAfterAnalysis
    } = appSelector((state) => ({
        ws_ask_user_data        : state.Websocket.ask_user_data,
        ws_connected            : state.Websocket.connection_connected,
        user: state.Auth.user,
        forceReloadAfterAnalysis: state.Websocket.forceReloadAfterAnalysis
    }));
    


    const ws = useContext(SocketContext);

    /*

    const {
        sendMessage,
        readyState,
      } = useWebSocket(config.WS_URL, {
        onOpen: () => console.log('Websockets  opened FileUpload_step'),
        //Will attempt to reconnect on all close events, such as server shutting down
        shouldReconnect: (closeEvent) => true,
        share:true
      });
      */
    
    useEffect(() => {
        if (ws_ask_user_data === false && ws_connected)
       {
        setTimeout(function() { 

            if (user !== null)
            {
                console.log("WebSocketInst send ", "user_login;" + user.token_server);
                if ( (ws as any) != null)
                    (ws as any).send("user_login;" + user.token_server);
            }

         }, 50);
           
       }
   })

    const getLayout = () => {
        let layoutCls = TwoColumnLayout;

        switch (layout.layoutType) {
            case LayoutTypes.LAYOUT_HORIZONTAL:
                layoutCls = HorizontalLayout;
                break;
            case LayoutTypes.LAYOUT_DETACHED:
                layoutCls = DetachedLayout;
                break;
            case LayoutTypes.LAYOUT_VERTICAL:
                layoutCls = VerticalLayout;
                break;
            default:
                layoutCls = TwoColumnLayout;
                break;
        }
        return layoutCls;
    };

    let Layout = getLayout();

    return (
        <BrowserRouter key={forceReloadAfterAnalysis} basename=''>
            <Switch>
                <Route  path={publicProtectedFlattenRoutes.map((r: any) => r['path'])}>
                    <DefaultLayout {...props} layout={layout}>
                        <Switch>
                            {publicProtectedFlattenRoutes.map((route: any, index: number) => {
                                return (
                                    !route.children && (
                                        <route.route
                                            key={index}
                                            path={route.path}
                                            roles={route.roles}
                                            exact={route.exact}
                                            component={route.component}
                                        />
                                    )
                                );
                            })}
                        </Switch>
                    </DefaultLayout>
                </Route>
                

                <Route path={authProtectedFlattenRoutes.map((r: any) => r['path'])}>
                    <Layout {...props}>
                        <Switch>
                            {authProtectedFlattenRoutes.map((route: any, index: number) => {
                                return (
                                    !route.children && (
                                        <route.route
                                            key={index}
                                            path={route.path}
                                            roles={route.roles}
                                            exact={route.exact}
                                            component={route.component}
                                        />
                                    )
                                );
                            })}
                        </Switch>
                    </Layout>
                </Route>

                
            </Switch>
        </BrowserRouter>
    );
};

export default Routes;
