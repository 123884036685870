// constants


enum WebsocketActionTypes {
    CHANGE_CONNECTION_STATUS = '@@layout/CHANGE_CONNECTION_STATUS',
    CHANGE_LAST_MESSAGE = '@@layout/CHANGE_LAST_MESSAGE',
    CHANGE_ASK_USER_DATA= '@@layout/CHANGE_ASK_USER_DATA',
    CHANGE_DATA= '@@layout/CHANGE_DATA',
    CHANGE_CURRENT_SAMPLE_EXP= '@@layout/CHANGE_CURRENT_SAMPLE_EXP',
    CHANGE_CURRENT_SAMPLE_ONLY= '@@layout/CHANGE_CURRENT_SAMPLE_ONLY',

    CHANGE_QC_VIOLIN_RAW_COUNTS= '@@layout/CHANGE_QC_VIOLIN_RAW_COUNTS',
    CHANGE_QC_VIOLIN_GENES_NB_PER_CELL= '@@layout/CHANGE_QC_VIOLIN_GENES_NB_PER_CELL',
    CHANGE_QC_VIOLIN_MITO_PERCENT= '@@layout/CHANGE_QC_VIOLIN_MITO_PERCENT',

    CHANGE_QC_CELLS_NUMBER_AFTER_FILTERS= '@@layout/CHANGE_QC_CELLS_NUMBER_AFTER_FILTERS',
    CHANGE_QC_GENES_NUMBER_AFTER_FILTERS= '@@layout/CHANGE_QC_GENES_NUMBER_AFTER_FILTERS',


    CHANGE_QC_SCATTER_GENENB_COUNTS= '@@layout/CHANGE_QC_SCATTER_GENENB_COUNTS',
    CHANGE_QC_SCATTER_MTPERCENT_COUNTS= '@@layout/CHANGE_QC_SCATTER_MTPERCENT_COUNTS',

    CHANGE_QC_SCATTER= '@@layout/CHANGE_QC_SCATTER',
    CHANGE_QC_SCATTER_2= '@@layout/CHANGE_QC_SCATTER_2',
    CHANGE_SAMPLES_NAME= '@@layout/CHANGE_SAMPLE_NAME',
    CHANGE_SAMPLES_TEST = '@@layout/CHANGE_SAMPLES_TEST',

    CHANGE_QC_COMPLEXITY_COMPLEXITY= '@@layout/CHANGE_QC_COMPLEXITY_COMPLEXITY',
    CHANGE_QC_COMPLEXITY_COUNTS= '@@layout/CHANGE_QC_COMPLEXITY_COUNTS',
    CHANGE_QC_COMPLEXITY_FEATURES= '@@layout/CHANGE_QC_COMPLEXITY_FEATURES',
    CHANGE_QC_COMPLEXITY_PERCENTMT= '@@layout/CHANGE_QC_COMPLEXITY_PERCENTMT',

    CHANGE_HIGHLY_FEATURE_GENES= '@@layout/CHANGE_HIGHLY_FEATURE_GENES',
    CHANGE_FEATURE_GENES_TABLE= '@@layout/CHANGE_FEATURE_GENES_TABLE',

    CHANGE_PCA_ELBOW= '@@layout/CHANGE_PCA_ELBOW',
    CHANGE_PCA_HEATMAPS= '@@layout/CHANGE_PCA_HEATMAPS',

    CHANGE_CLUSTER_PROJETION_TYPE= '@@layout/CHANGE_CLUSTER_PROJETION_TYPE',
    CHANGE_CLUSTER_SCATTER_2D= '@@layout/CHANGE_CLUSTER_SCATTER_2D',
    CHANGE_CLUSTER_LIST= '@@layout/CHANGE_CLUSTER_LIST',
    CHANGE_CLUSTER_SAMPLE_FILTER= '@@layout/CHANGE_CLUSTER_SAMPLE_FILTER',
    CHANGE_CLUSTER_MARKERS= '@@layout/CHANGE_CLUSTER_MARKERS',
    CHANGE_CLUSTER_MARKERS_CSV= '@@layout/CHANGE_CLUSTER_MARKERS_CSV',
    CHANGE_CLUSTER_MARKERS_CSV_FULL_COPY= '@@layout/CHANGE_CLUSTER_MARKERS_CSV_FULL_COPY',
    CHANGE_CLUSTER_SCATTER_3D= '@@layout/CHANGE_CLUSTER_SCATTER_3D',
    CHANGE_CLUSTER_CLUSTERS_CELLSNB_TABLE= '@@layout/CHANGE_CLUSTER_CLUSTERS_CELLSNB_TABLE',
    CHANGE_CLUSTER_CLUSTERS_CELLSNB_CSV= '@@layout/CHANGE_CLUSTER_CLUSTERS_CELLSNB_CSV',

    CHANGE_GENEEXPRESSION_GENES_NAME= '@@layout/CHANGE_GENEEXPRESSION_GENES_NAME',
    CHANGE_GENEEXPRESSION_VIOLIN_GENES= '@@layout/CHANGE_GENEEXPRESSION_VIOLIN_GENES',
    CHANGE_GENEEXPRESSION_SCATTER_GENES= '@@layout/CHANGE_GENEEXPRESSION_SCATTER_GENES',
    CHANGE_GENEEXPRESSION_RAW_COUNTS_TYPE= '@@layout/CHANGE_GENEEXPRESSION_RAW_COUNTS_TYPE',
    CHANGE_GENEEXPRESSION_TABLE = '@@layout/CHANGE_GENEEXPRESSION_TABLE',
    CHANGE_GENEEXPRESSION_TABLE_CSV = '@@layout/CHANGE_GENEEXPRESSION_TABLE_CSV',
    CHANGE_GENEEXPRESSION_TABLE_FROM_CLUSTER = '@@layout/CHANGE_GENEEXPRESSION_TABLE_FROM_CLUSTER',

    CHANGE_CLEAR_ALL= '@@layout/CHANGE_CLEAR_ALL',
    CHANGE_CLEAR_GENES_EXPRESSION= '@@layout/CHANGE_CLEAR_GENES_EXPRESSION',
    CHANGE_CLEAR_DIFF_GENES_EXPRESSION= '@@layout/CHANGE_CLEAR_DIFF_GENES_EXPRESSION',
    CHANGE_CLEAR_CLUSTERS= '@@layout/CHANGE_CLEAR_CLUSTERS',
    CHANGE_CLEAR_CLUSTER_MARKERS= '@@layout/CHANGE_CLEAR_CLUSTER_MARKERS',
    CHANGE_CLEAR_DE_INTEGRATION= '@@layout/CHANGE_CLEAR_DE_INTEGRATION',
    CHANGE_CLEAR_PCA= '@@layout/CHANGE_CLEAR_PCA',

    CHANGE_TEST_GRAPH= '@@layout/CHANGE_TEST_GRAPH',

    CHANGE_H5_TREEVIEW= '@@layout/CHANGE_H5_TREEVIEW',

    CHANGE_LOAD_ALL_DATA= '@@layout/CHANGE_LOAD_ALL_DATA',
    CHANGE_WEBSOCKET_ACK= '@@layout/CHANGE_WEBSOCKET_ACK',
    CHANGE_WEBSOCKET_SENDMESSAGE_ACK= '@@layout/CHANGE_WEBSOCKET_SENDMESSAGE_ACK',
    CHANGE_WEBSOCKET_FORCE_RELOAD= '@@layout/CHANGE_WEBSOCKET_FORCE_RELOAD',
    CHANGE_FORCE_RELOAD_AFTER_LOAD_ANALYSIS= '@@layout/CHANGE_FORCE_RELOAD_AFTER_LOAD_ANALYSIS',
}

export interface WebsocketStateTypes {
    connection_connected: boolean;
    lastMessage: String;
    ask_user_data: boolean;
    data: any;
    currentSamplesExp: any;
    qc_violinRawCounts: any;
    qc_violinGenesNbPerCell:any;
    qc_violinMitoPercent:any;
    qc_scatter_mt_pecentage_counts:any;
    qc_scatter_gene_nb_counts:any;
    qc_scatter:any;
    qc_scatter_2:any;
    samplesName:any;
    test:any;
    qc_complexity_complexity:any;
    qc_complexity_counts:any;
    qc_complexity_features:any;
    qc_complexity_percentMt:any;
    highlyFeatureGenes:any;
    pca_elbow:any
    pca_heatmaps:any;

    cluster_projection_type:string;
    cluster_scatter_2d:any;
    cluster_markers:any;
    cluster_markers_csv:any;
    cluster_scatter_3d:any;

    genesExpression_genesName:any;
    geneExpression_violin_genes:any;
    geneExpression_scatter_genes:any;
    geneExpression_rawCountsType:string;

    test_graph:any;

    h5_treeview:any;
    websocketAck:number;
    websocketSendMessageAck:number;
    webscoketForceReload:number;
    forceReloadAfterAnalysis:number;
}

export { WebsocketActionTypes };
