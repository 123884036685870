import Routes from './routes/Routes';
import React from 'react';

//import { configureFakeBackend } from './helpers';

// Themes

// For Default import Default.scss
import './assets/scss/Default.scss';
//import './assets/scss/Modern.scss';

//import WebSocketProvider from './websocketComputation/WebSocket';
import ComputeStepProvider from './pages/apps/common/ComputeStep'
//import WebSocketInst from './websocketComputation/WebSocketInst'


import NotificationGlobal from './pages/apps/notifications';
//import WebSocket from './websocketComputation/WebSocket';


// configure fake backend
//configureFakeBackend();

import { configureStore } from './redux/store';

import { useSelector,Provider  } from 'react-redux';
import { SocketProvider } from './websocketComputation/SocketContext';

import ReactGA from "react-ga4";
import config from './config';
//import { hotjar } from 'react-hotjar';
import Hotjar from '@hotjar/browser';
import { ReactTagManager } from 'react-gtm-ts';

//import TagManager from 'react-gtm-module'
//const tagManagerArgs = { gtmId: 'GTM-5L7BF8BV' }

 
if (config.ENABLE_ANALYTICS)
{
    console.log("analytics one")
    // TagManager.initialize(tagManagerArgs)
    ReactGA.initialize('G-4QDNM0EFP2') ; 
    const siteId = 5341882;
    const hotjarVersion = 6;
    
    Hotjar.init(siteId, hotjarVersion);

    //hotjar.initialize({id:5341839, sv:6});

    
    var result = ReactTagManager.init({
        code: 'GTM-5L7BF8BV', // GTM Code
        debug: true, // debug mode (default false)
        performance: false, // starts GTM only after user interaction (improve initial page load)
    });

  console.log("ReactTagManager result debug true", result)

  }
    

//import Routes_test from './Routes_test';


const App = () => {
   //const ws = WebsocketComputation();
   //MyWs.init();

    console.log("App::render !!!!!!! js")
    const storeCustom = configureStore({})

    return (
    <>
        <Provider store={storeCustom}>
 
            <SocketProvider>
                <ComputeStepProvider><Routes/></ComputeStepProvider> ;
            </SocketProvider>

            <NotificationGlobal/>
            
        </Provider>
    </>
    )
};

export default App;