export enum ParamsActionTypeConst {
    CHANGE_PROJECTS_METADATAS = '@@Params/CHANGE_PROJECTS_METADATAS',
    CHANGE_ALL_METADATAS = '@@Params/CHANGE_ALL_METADATAS',
    CHANGE_PROJECTS_METADATAS_VALUES = '@@Params/CHANGE_PROJECTS_METADATAS_VALUES',
    CHANGE_ALL_METADATAS_VALUES = '@@Params/CHANGE_ALL_METADATAS_VALUES',
    CHANGE_QC_COMPLEXITY_XAXIS = '@@Params/CHANGE_QC_COMPLEXITY_XAXIS',
    CHANGE_QC_COMPLEXITY_YAXIS = '@@Params/CHANGE_QC_COMPLEXITY_YAXIS',
    CHANGE_HIGHLYFEATURESGENES_TOP_NUMBER = '@@Params/CHANGE_HIGHLYFEATURESGENES_TOP_NUMBER',
    CHANGE_QC_SCATTER_GENENB_AXIS_TYPE = '@@Params/CHANGE_QC_SCATTER_GENENB_AXIS_TYPE',
    CHANGE_QC_SCATTER_MITO_AXIS_TYPE = '@@Params/CHANGE_QC_SCATTER_MITO_AXIS_TYPE',
    CHANGE_IS_DE_INTEGRATION = '@@Params/CHANGE_IS_DE_INTEGRATION',
    CHANGE_CLUSTER_CLUSTER_NAMES = '@@Params/CHANGE_CLUSTER_CLUSTER_NAMES',
    CHANGE_CLUSTER_CLUSTER_NAMES_USER_RENAME = '@@Params/CHANGE_CLUSTER_CLUSTER_NAMES_USER_RENAME',
    UPDATE_NOTIFICATION = '@@Params/UPDATE_NOTIFICATION',
    CHANGE_NOTIFICATION = '@@Params/CHANGE_NOTIFICATION',
    CHANGE_MARKERS_TABLE_CURRENT_CLUSTER = '@@Params/CHANGE_MARKERS_TABLE_CURRENT_CLUSTER',
    CHANGE_MARKERS_TABLE_ALL_FILTERS = '@@Params/CHANGE_MARKERS_TABLE_ALL_FILTERS',
    CHANGE_DIFF_GENE_EXPRESS_TABLE_FILTERS = '@@Params/CHANGE_DIFF_GENE_EXPRESS_TABLE_FILTERS',
    CLEAR_CLUSTER_INFO = '@@Params/CLEAR_CLUSTER_INFO',
    CLEAR_ALL = '@@Params/CLEAR_ALL',
    RESET_MARKERS_FILTERS = '@@Params/RESET_MARKERS_FILTERS',
    RESET_DIFF_GENE_EXPRESS_TABLE_FILTERS = '@@Params/RESET_DIFF_GENE_EXPRESS_TABLE_FILTERS',
    CHANGE_H5_SELECTED_MATRIX_FILES = '@@Params/CHANGE_H5_SELECTED_MATRIX_FILES',
    CHANGE_H5_SELECTED_BARCODES_FILES = '@@Params/CHANGE_H5_SELECTED_BARCODES_FILES',
    CHANGE_H5_SELECTED_FEATURES_FILES = '@@Params/CHANGE_H5_SELECTED_FEATURES_FILES',
    CHANGE_H5_SAMPLE_NAME = '@@Params/CHANGE_H5_SAMPLE_NAME',
    CHANGE_LOAD_ALL_DATA = '@@Params/CHANGE_LOAD_ALL_DATA',
    CHANGE_SELECT_CLUSTER_CELLSNB_TABLE = '@@Params/CHANGE_SELECT_CLUSTER_CELLSNB_TABLE',
    CHANGE_SERVER_INFOS = '@@Params/CHANGE_SERVER_INFOS',
}
