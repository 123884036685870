// constants
import { ParamsActionTypeConst } from './constants';

export interface ParamsActionType<TPayload> {
    type: ParamsActionTypeConst.CHANGE_PROJECTS_METADATAS
        | ParamsActionTypeConst.CHANGE_ALL_METADATAS
        | ParamsActionTypeConst.CHANGE_PROJECTS_METADATAS_VALUES
        | ParamsActionTypeConst.CHANGE_ALL_METADATAS_VALUES
        | ParamsActionTypeConst.CHANGE_QC_SCATTER_GENENB_AXIS_TYPE
        | ParamsActionTypeConst.CHANGE_QC_SCATTER_MITO_AXIS_TYPE
        | ParamsActionTypeConst.CHANGE_QC_COMPLEXITY_XAXIS
        | ParamsActionTypeConst.CHANGE_QC_COMPLEXITY_YAXIS
        | ParamsActionTypeConst.CHANGE_HIGHLYFEATURESGENES_TOP_NUMBER
        | ParamsActionTypeConst.CHANGE_IS_DE_INTEGRATION
        | ParamsActionTypeConst.CHANGE_CLUSTER_CLUSTER_NAMES
        | ParamsActionTypeConst.CHANGE_CLUSTER_CLUSTER_NAMES_USER_RENAME
        | ParamsActionTypeConst.CHANGE_MARKERS_TABLE_CURRENT_CLUSTER
        | ParamsActionTypeConst.CHANGE_MARKERS_TABLE_ALL_FILTERS
        | ParamsActionTypeConst.CHANGE_DIFF_GENE_EXPRESS_TABLE_FILTERS
        | ParamsActionTypeConst.UPDATE_NOTIFICATION
        | ParamsActionTypeConst.CHANGE_NOTIFICATION
        | ParamsActionTypeConst.CLEAR_CLUSTER_INFO
        | ParamsActionTypeConst.CLEAR_ALL
        | ParamsActionTypeConst.RESET_MARKERS_FILTERS
        | ParamsActionTypeConst.RESET_DIFF_GENE_EXPRESS_TABLE_FILTERS
        | ParamsActionTypeConst.CHANGE_H5_SELECTED_MATRIX_FILES
        | ParamsActionTypeConst.CHANGE_H5_SELECTED_BARCODES_FILES
        | ParamsActionTypeConst.CHANGE_H5_SELECTED_FEATURES_FILES
        | ParamsActionTypeConst.CHANGE_H5_SAMPLE_NAME
        | ParamsActionTypeConst.CHANGE_LOAD_ALL_DATA
        | ParamsActionTypeConst.CHANGE_SELECT_CLUSTER_CELLSNB_TABLE
        | ParamsActionTypeConst.CHANGE_SERVER_INFOS;
    payload?: TPayload;
}


export const changeProjectMetadatas = (project_name: String, metadatas: any): ParamsActionType<any | String> => ({
    type: ParamsActionTypeConst.CHANGE_PROJECTS_METADATAS,
    payload: {project_name, metadatas},
});

export const changeAllMetadatas = (metadatas: any): ParamsActionType<any> => ({
    type: ParamsActionTypeConst.CHANGE_ALL_METADATAS,
    payload: metadatas,
});

export const changeProjectMetadatasValues = (project_name: String, metadatas: any): ParamsActionType<any | String> => ({
    type: ParamsActionTypeConst.CHANGE_PROJECTS_METADATAS_VALUES,
    payload: {project_name, metadatas},
});

export const changeAllMetadatasValues = (metadatas: any): ParamsActionType<any> => ({
    type: ParamsActionTypeConst.CHANGE_ALL_METADATAS_VALUES,
    payload: metadatas,
});

export const changeQcScatterGeneNbXAxisType = (data:string): ParamsActionType<any> => ({
    type: ParamsActionTypeConst.CHANGE_QC_SCATTER_GENENB_AXIS_TYPE,
    payload: data,
});

export const changeQcScatterMitoXAxisType = (data:string): ParamsActionType<any> => ({
    type: ParamsActionTypeConst.CHANGE_QC_SCATTER_MITO_AXIS_TYPE,
    payload: data,
});


export const changeQcComplexityXAxis = (data:string): ParamsActionType<any> => ({
    type: ParamsActionTypeConst.CHANGE_QC_COMPLEXITY_XAXIS,
    payload: data,
});

export const changeQcComplexityYAxis = (data:string): ParamsActionType<any> => ({
    type: ParamsActionTypeConst.CHANGE_QC_COMPLEXITY_YAXIS,
    payload: data,
});

export const changeHighlyFeaturesGenesTopNb = (data:any): ParamsActionType<any> => ({
    type: ParamsActionTypeConst.CHANGE_HIGHLYFEATURESGENES_TOP_NUMBER,
    payload: data,
});


export const changeIsDeIntegration = (data:boolean): ParamsActionType<any> => ({
    type: ParamsActionTypeConst.CHANGE_IS_DE_INTEGRATION,
    payload: data,
});

export const changeClusterNames = (data:boolean): ParamsActionType<any> => ({
    type: ParamsActionTypeConst.CHANGE_CLUSTER_CLUSTER_NAMES,
    payload: data,
});

export const changeClusterNamesUserRename = (cluster_id: Number, cluster_new_name:string): ParamsActionType<any> => ({
    type: ParamsActionTypeConst.CHANGE_CLUSTER_CLUSTER_NAMES_USER_RENAME,
    payload: {cluster_id, cluster_new_name},
});

export const changeMarkerTableCurrentCluster = (cluster_id: Number): ParamsActionType<any> => ({
    type: ParamsActionTypeConst.CHANGE_MARKERS_TABLE_CURRENT_CLUSTER,
    payload: cluster_id,
});

export const changeMarkerTableOneFilter = (name:string, filter: any): ParamsActionType<any> => ({
    type: ParamsActionTypeConst.CHANGE_MARKERS_TABLE_ALL_FILTERS,
    payload: {name, filter},
});

export const changeDiffGeneExpressTableOneFilter = (name:string, filter: any): ParamsActionType<any> => ({
    type: ParamsActionTypeConst.CHANGE_DIFF_GENE_EXPRESS_TABLE_FILTERS,
    payload: {name, filter},
});

export const addNotification = (notification:any): ParamsActionType<any> => ({
    type: ParamsActionTypeConst.UPDATE_NOTIFICATION,
    payload: notification,
});

export const changeNotification = (notification:any): ParamsActionType<any> => ({
    type: ParamsActionTypeConst.CHANGE_NOTIFICATION,
    payload: notification,
});

export const clearClusterInfo = (): ParamsActionType<string> => ({
    type: ParamsActionTypeConst.CLEAR_CLUSTER_INFO,
    payload: undefined,
});

export const clearAllParams = (): ParamsActionType<string> => ({
    type: ParamsActionTypeConst.CLEAR_ALL,
    payload: undefined,
});
 
export const resetMarkersFilters = (): ParamsActionType<string> => ({
    type: ParamsActionTypeConst.RESET_MARKERS_FILTERS,
    payload: undefined,
});
 
export const resetDiffGeneExpressFilters = (): ParamsActionType<string> => ({
    type: ParamsActionTypeConst.RESET_DIFF_GENE_EXPRESS_TABLE_FILTERS,
    payload: undefined,
});
 
export const updateH5MatricesFiles = (files:any): ParamsActionType<any> => ({
    type: ParamsActionTypeConst.CHANGE_H5_SELECTED_MATRIX_FILES,
    payload: files,
});

export const updateH5BarcodesFile = (file:string): ParamsActionType<any> => ({
    type: ParamsActionTypeConst.CHANGE_H5_SELECTED_BARCODES_FILES,
    payload: file,
});

export const updateH5FeaturesFile = (file:string): ParamsActionType<any> => ({
    type: ParamsActionTypeConst.CHANGE_H5_SELECTED_FEATURES_FILES,
    payload: file,
});

export const updateH5SampleName = (file:string): ParamsActionType<any> => ({
    type: ParamsActionTypeConst.CHANGE_H5_SAMPLE_NAME,
    payload: file,
});

export const updateAllParamsData = (data:any): ParamsActionType<any> => ({
    type: ParamsActionTypeConst.CHANGE_LOAD_ALL_DATA,
    payload: data,
});

export const updateSelectClusterCellsNbTable = (data:any): ParamsActionType<any> => ({
    type: ParamsActionTypeConst.CHANGE_SELECT_CLUSTER_CELLSNB_TABLE,
    payload: data,
});

export const updateServerInfos = (data:any): ParamsActionType<any> => ({
    type: ParamsActionTypeConst.CHANGE_SERVER_INFOS,
    payload: data,
});