const config = {
    //API_URL: "http://161.97.129.77",
    //WS_URL: "ws://161.97.129.77:8080"
    API_URL: "https://sc-analyzer.com",
    WS_URL: "wss://sc-analyzer.com/ws",
    ENABLE_ANALYTICS: true,    
    ENABLE_TRIAL_BYPASS: false
};

export default config;
