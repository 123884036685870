


class Util {
    static displayHugeNumbers(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      }

    static isThereForbiddenChar(name) {
      var format = /[`!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?~]/;

      return format.test(name);
    } 

    static readableBytes(bytes) {
      if (bytes ===0) {
        return "0 GB"
      }

      var i = Math.floor(Math.log(bytes) / Math.log(1024)),
      sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  
      return (bytes / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + sizes[i];
  }

  static getTimeString() {
    var today = new Date();
    var h = today.getHours();
    var m = today.getMinutes();
    var s = today.getSeconds();

    return (h+":"+m+":"+s)
  }

    /*
    static updateRefreshToken(tokenId, tokenValue) {
      return QuickBoooks.update(tokenId, { $set: { refreshToken: tokenValue } });
    }
    */
  }
  

export default Util