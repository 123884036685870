// action constants
import { ParamsActionType } from './actions';
import { ParamsActionTypeConst } from './constants';

interface paramsData {
    dataSelection_metadatas:any;// {"project1":[{"metadata1":["value1", "value2"]}], "project2": ...}
    metadatas_values:any; // {"project1": {"sample 1": {{"metaData 1":"propertyX"}, {"metaData 2":"propertyY"}} },  {"sample 2": ...} }, {"project 2" : ...}

    qc_scatter_geneNb_XaxisType: string;
    qc_scatter_mito_XaxisType: string;

    qc_complexity_xAxis: string;
    qc_complexity_yAxis: string;

    highlyFeatureGenes_top_number:any;

    isDeIntegration:boolean;

    cluster_cluster_names:any;
    clusters_clusters_user_rename:any;

    markers_table_all_filters:any;
    diff_gene_express_table_filters:any;
    markers_table_key:number;
    notifications:any;

    matricesFiles:any;
    barcodesFile:string;
    featuresFile:string;
    h5SampleName:string;

    selectedTableClustersCellsNb:any;
    
    server_infos:any;
}

const INIT_STATE = {
    dataSelection_metadatas:{},
    metadatas_values:{},
    qc_scatter_geneNb_XaxisType: "linear",
    qc_scatter_mito_XaxisType: "linear",
    qc_complexity_xAxis: "nUMI",
    qc_complexity_yAxis: "nGene",
    highlyFeatureGenes_top_number:{ value: 0, label: 'Do not display genes name' },
    isDeIntegration: false,
    cluster_cluster_names:[],
    clusters_clusters_user_rename:{}, // <id_cluster, name"
    markers_table_current_cluster:0,
    markers_table_all_filters:{"avg_logFC":{number:0.25, comparator:'>'},
                                "p_val_ROC_AUC":{number:0, comparator:''},
                                "p_val_mann":{number:0, comparator:''},
                                "p_val_adjusted":{number:0, comparator:''}},
    diff_gene_express_table_filters:{"avg_logFC":{number:0.25, comparator:'>'},
                                    "p_val_ROC_AUC":{number:0, comparator:''},
                                    "p_val_mann":{number:0, comparator:''},
                                    "p_val_adjusted":{number:0, comparator:''}},
    markers_table_key:1,
    notifications:[],

    matricesFiles:[], // ex : should be matrix/data, matrix/indices, matrix/indptr (reswaped in cpp)
    barcodesFile:"",
    featuresFile:"",
    h5SampleName:"",
    selectedTableClustersCellsNb:{}, // {value, label}
    server_infos:{},
};

const Params = (state: paramsData = INIT_STATE, action: ParamsActionType<string | any >) => {
    switch (action.type) {
        case ParamsActionTypeConst.CHANGE_PROJECTS_METADATAS:
            return {
                ...state,
                dataSelection_metadatas:{
                    ...state.dataSelection_metadatas,  [action.payload.project_name] :action.payload.metadatas
                }
            };

        case ParamsActionTypeConst.CHANGE_ALL_METADATAS:
            return {
                ...state,
                dataSelection_metadatas: action.payload
            };

        case ParamsActionTypeConst.CHANGE_PROJECTS_METADATAS_VALUES:
            return {
                ...state,
                metadatas_values:{
                    ...state.metadatas_values,  [action.payload.project_name] :action.payload.metadatas
                }
            };

        case ParamsActionTypeConst.CHANGE_ALL_METADATAS_VALUES:
            return {
                ...state,
                metadatas_values: action.payload
            };

        case ParamsActionTypeConst.CHANGE_QC_SCATTER_GENENB_AXIS_TYPE:
            return {
                ...state,
                qc_scatter_geneNb_XaxisType: action.payload
            };

        case ParamsActionTypeConst.CHANGE_QC_SCATTER_MITO_AXIS_TYPE:
            return {
                ...state,
                qc_scatter_mito_XaxisType: action.payload          
            };

        case ParamsActionTypeConst.CHANGE_QC_COMPLEXITY_XAXIS:
            return {
                ...state,
                qc_complexity_xAxis: action.payload
            };

        case ParamsActionTypeConst.CHANGE_QC_COMPLEXITY_YAXIS:
            return {
                ...state,
                qc_complexity_yAxis: action.payload          
            };

        case ParamsActionTypeConst.CHANGE_HIGHLYFEATURESGENES_TOP_NUMBER:
            return {
                ...state,
                highlyFeatureGenes_top_number: action.payload          
            };

        case ParamsActionTypeConst.CHANGE_IS_DE_INTEGRATION:
            return {
                ...state,
                isDeIntegration: action.payload          
            };

        case ParamsActionTypeConst.CHANGE_CLUSTER_CLUSTER_NAMES:
            return {
                ...state,
                cluster_cluster_names: action.payload          
            };

        case ParamsActionTypeConst.CHANGE_CLUSTER_CLUSTER_NAMES_USER_RENAME:
            return {
                ...state,
                clusters_clusters_user_rename:{
                    ...state.clusters_clusters_user_rename,  [action.payload.cluster_id] :action.payload.cluster_new_name
                }
            };

        case ParamsActionTypeConst.CHANGE_MARKERS_TABLE_CURRENT_CLUSTER:
            return {
                ...state,
                markers_table_current_cluster: action.payload          
            };

        case ParamsActionTypeConst.CHANGE_MARKERS_TABLE_ALL_FILTERS:
            return {
                ...state,
                markers_table_all_filters:{
                    ...state.markers_table_all_filters,  [action.payload.name] :action.payload.filter
                }
            };

        case ParamsActionTypeConst.CHANGE_DIFF_GENE_EXPRESS_TABLE_FILTERS:
            return {
                ...state,
                diff_gene_express_table_filters:{
                    ...state.diff_gene_express_table_filters,  [action.payload.name] :action.payload.filter
                }
            };    
            
        case ParamsActionTypeConst.UPDATE_NOTIFICATION:
            return { 
                ...state,
                notifications: state.notifications.concat(action.payload)
            };    

        case ParamsActionTypeConst.CHANGE_NOTIFICATION:
            return { 
                ...state,
                notifications: action.payload
            };   

        case ParamsActionTypeConst.CLEAR_CLUSTER_INFO:
            return {
                ...state,
                cluster_cluster_names:[],
                clusters_clusters_user_rename:{},
                markers_table_current_cluster:0,
                selectedTableClustersCellsNb:{}
            };
        
        case ParamsActionTypeConst.CLEAR_ALL:
            return {
                ...INIT_STATE
            };

        case ParamsActionTypeConst.RESET_MARKERS_FILTERS:
            return {
                ...state,
                markers_table_all_filters:INIT_STATE.markers_table_all_filters,
                markers_table_key:state.markers_table_key+1// to change the key of the table and force update
            };

        case ParamsActionTypeConst.RESET_DIFF_GENE_EXPRESS_TABLE_FILTERS:
            return {
                ...state,
                diff_gene_express_table_filters:INIT_STATE.diff_gene_express_table_filters,
                markers_table_key:state.markers_table_key+1// to change the key of the table and force update
            };

        case ParamsActionTypeConst.CHANGE_H5_SELECTED_MATRIX_FILES:
            return {
                ...state,
                matricesFiles: action.payload          
            };

        case ParamsActionTypeConst.CHANGE_H5_SELECTED_BARCODES_FILES:
            return {
                ...state,
                barcodesFile: action.payload          
            };

        case ParamsActionTypeConst.CHANGE_H5_SELECTED_FEATURES_FILES:
            return {
                ...state,
                featuresFile: action.payload          
            };

        case ParamsActionTypeConst.CHANGE_H5_SAMPLE_NAME:
            return {
                ...state,
                h5SampleName: action.payload          
            };

        case ParamsActionTypeConst.CHANGE_LOAD_ALL_DATA:
            var newState = {}
            Object.assign(newState, INIT_STATE, action.payload, {dataSelection_metadatas: state.dataSelection_metadatas, metadatas_values: state.metadatas_values})
            return {
                ...newState
            };

        case ParamsActionTypeConst.CHANGE_SELECT_CLUSTER_CELLSNB_TABLE:
            return {
                ...state,
                selectedTableClustersCellsNb: action.payload          
            };
            
        case ParamsActionTypeConst.CHANGE_SERVER_INFOS:
            return {
                ...state,
                server_infos: action.payload          
            };

        default:
            return state;
    }
};

export default Params;


/*
notifications:[{description:"dComme la classe fournit une interface plus spécialisée que QAbstractItemModel, elle n'est pas adaptée pour représenter un modèle d'arbre. Il est nécessaire",
                        type:"info",
                    id:55,
                delay:6000},
                    {description:"simple",
                    type:"warning",
                        id:3,
                        delay:10000}]

                        */